/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import "../App.css";
import image_a from "../assets/A.svg";
import feature_1 from "../assets/feature_1.png";
import feature_2 from "../assets/feature_2.png";
import feature_3 from "../assets/feature_3.png";
import college_1 from "../assets/college_1.png";
import college_2 from "../assets/college_2.png";
import college_3 from "../assets/college_3.png";
import college_4 from "../assets/college_4.png";
import college_5 from "../assets/college_5.svg";
import company_1 from "../assets/company_1.jpg";
import company_2 from "../assets/company_2.png";
import company_3 from "../assets/company_3.png";
import student from "../assets/student.png";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Main = () => {
  const [userEmail, setUserEmail] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const navigate = useNavigate();
  const baseUrl ="https://backend.coursex.us";

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/api/webUser/create/`, {
        email: userEmail,
        schoolName: schoolName,
      });
      navigate("/thanks");
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const pageChange = () => {
    navigate("/terms");
  };

  return (
    <div className="Screen">
      <p className="heading">
        Course
        <span>
          <strong>X</strong>
        </span>
      </p>
      <p className="tagline">
        We are building The World’s First-Ever Collaborative{" "}
        <span className="blue">Academic</span> Workspace
      </p>
      <a href="https://coursex.us" target="_blank" rel="noopener noreferrer">
        <img src={image_a} className="Image_A" alt="image_a" />
      </a>
      <div className="releaseBox">
        <div className="dot"></div>
        <p className="releaseDate">Releasing Spring'25</p>
      </div>
      <div className="featureBox">
        <img src={feature_1} className="featureImage2" alt="feature_1" />
        <img src={feature_2} className="featureImage" alt="feature_2" />
        <img src={feature_3} className="featureImage2" alt="feature_3" />
      </div>
      <p className="trustedBy"> Trusted by students at </p>
      <div className="collegeBox">
        <img src={college_1} className="mit" alt="college_1" />
        <img src={college_2} className="otherCollege" alt="college_2" />
        <img src={college_3} className="otherCollege" alt="college_3" />
        <img src={college_4} className="otherCollege" alt="college_4" />
        <img src={college_5} className="otherCollege" alt="college_5" />
      </div>
      <div className="collegeBoxMobile">
        <img src={college_1} className="mit" alt="college_1" />
        <img src={college_2} className="otherCollege" alt="college_2" />
      </div>
      <div className="collegeBoxMobile">
        <img src={college_3} className="otherCollege" alt="college_1" />
        <img src={college_4} className="otherCollege" alt="college_2" />
      </div>
      <div className="collegeBoxMobile2">
        <img src={college_5} className="rice" />
      </div>
      <p className="trustedBy"> Built by engineers at </p>
      <div className="companyBox">
        <img src={company_1} className="nasa" alt="company_1" />
        <img src={company_2} className="ea" alt="company_2" />
        <img src={company_3} className="teradata" alt="company_3" />
      </div>
      <p className="launch">Launch CourseX at your school.</p>
      <div className="submitBox">
        <img src={student} className="student" alt="student" />
        <div className="inputContainer">
          <form onSubmit={handleSubmit}>
            <input
              className="inputBox"
              placeholder="Your school email address"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
            <input
              className="inputBox"
              placeholder="What school do you go to?"
              value={schoolName}
              onChange={(e) => setSchoolName(e.target.value)}
            />
            <div style={{ justifyContent: "center", display: "flex" }}>
              <button type="submit" className="button">
                submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="bottomDev">
        <div className="bottomLeft">
          <p className="coursex">© 2024 CourseX</p>
        </div>
        <div className="bottomRight">
          <p className="terms" onClick={pageChange}>
            terms and conditions
          </p>
          <p className="terms" onClick={pageChange}>
            privacy policy
          </p>
        </div>
      </div>
    </div>
  );
};

export default Main;
